import React, { useState } from 'react';
import Supabase from './SupabaseClient';

const UpdatePassword = ({ language }) => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const supabase = Supabase();

  const handleUpdatePassword = async () => {
    if (!supabase) return; // Wait for Supabase to be initialized

    try {
      if (password !== confirmPassword) {
        setError(t.noMatchPw);
        return;
      }

      const { error } = await supabase.auth.updateUser({
        password
      });

      if (error) {
        setError(error.message);
      } else {
        setMessage(t.redirectingPw);
        setTimeout(() => {
          window.location.href = '/signin';
        }, 5000); // 15 seconds
      }
    } catch (error) {
      setError(error.message);
    }
  };

  const texts = {
    en: {
      addPw: 'Add Your Password',
      updatePw: 'Update Password',
      placeholderPw: 'Enter Your New Password',
      confirmPw: 'Confirm Your New Password',
      noMatchPw: 'Passwords do not match',
      redirectingPw: 'Password updated, we are redirecting you to your account',
    },
    fr: {
      addPw: 'Ajoutez votre mot de passe',
      updatePw: 'Mettre à jour le mot de passe',
      placeholderPw: 'Entrez votre nouveau mot de passe',
      confirmPw: 'Confirmez votre nouveau mot de passe',
      noMatchPw: 'Les mots de passe ne correspondent pas',
      redirectingPw: 'Mot de passe mis à jour, nous vous redirigeons vers votre compte',
    },
    es: {
      addPw: 'Añade tu contraseña',
      updatePw: 'Actualizar contraseña',
      placeholderPw: 'Introduce tu nueva contraseña',
      confirmPw: 'Confirma tu nueva contraseña',
      noMatchPw: 'Las contraseñas no coinciden',
      redirectingPw: 'Contraseña actualizada, te estamos redirigiendo a tu cuenta',
    },
    de: {
      addPw: 'Geben Sie Ihr Passwort ein',
      updatePw: 'Passwort aktualisieren',
      placeholderPw: 'Geben Sie Ihr neues Passwort ein',
      confirmPw: 'Bestätigen Sie Ihr neues Passwort',
      noMatchPw: 'Die Passwörter stimmen nicht überein',
      redirectingPw: 'Passwort aktualisiert, wir leiten Sie zu Ihrem Konto weiter',
    },
    lt: {
      addPw: 'Įveskite savo slaptažodį',
      updatePw: 'Atnaujinti slaptažodį',
      placeholderPw: 'Įveskite savo naują slaptažodį',
      confirmPw: 'Patvirtinkite savo naują slaptažodį',
      noMatchPw: 'Slaptažodžiai nesutampa',
      redirectingPw: 'Slaptažodis atnaujintas, nukreipiame jus į jūsų paskyrą',
    }
  };

  const t = texts[language];

  return (
    <div className='slide'>
      {!message && (
        <div className='formElements'>
          <h3 className='noMarginP centeredText capText'>{t.addPw}</h3>
          <input className='mainInput inputSmall'
            type="password"
            id="passwordInput"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder={t.placeholderPw}
          />
          <input className='mainInput inputSmall'
            type="password"
            id="confirmPasswordInput"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            placeholder={t.confirmPw}
          />
          <button className='btnBasic' onClick={handleUpdatePassword}>{t.updatePw}</button>
        </div>
      )}
      {error && <div>{error}</div>}
      {message && <p>{message}</p>}
    </div>
  );
};

export default UpdatePassword;
