import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const ConfirmProduct = ({ productId, userID, trackers, setShowPlans, language }) => {
  const [productData, setProductDataState] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProductData = async () => {
      setLoading(true);
      const response = await fetch(`/getProduct?id=${productId}`);
      const data = await response.json();

      if (data.product) {
        setProductDataState(data.product);
        setLoading(false);
      } else {
        console.log("no product found")
      }
    };
    fetchProductData();
  }, [productId]);

  const handleConfirm = async () => {
    if (trackers === 0) {
      setShowPlans(true);
    } else {
      try {
        const payload = { productId, userID, priceText: productData.priceText };
        const response = await fetch('/confirmProduct', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(payload)
        });
        console.log(response);
      } catch (error) {
        console.error('Error confirming product:', error);
      }
    }
  };

  const handleCancel = async () => {
    try {
      const response = await fetch(`/OutDelete?id=${productId}`, {
        method: 'DELETE'
      });
      console.log(response);
      navigate("/");
    } catch (error) {
      console.error('Error deleting product:', error);
    }
  };

  const texts = {
    en: {
      loading: 'We are looking for the data of the product, please wait',
      notFound: 'Data for this product has not been found',
      confirmTracking: 'Do you still want to track this item?',
      yesTrack: "Yes, let's track it!",
      noChangeMind: 'No, I changed my mind',
    },
    fr: {
      loading: 'Nous recherchons les données du produit, veuillez patienter',
      notFound: 'Les données pour ce produit n\'ont pas été trouvées',
      confirmTracking: 'Voulez-vous toujours suivre cet article ?',
      yesTrack: 'Oui, suivons-le !',
      noChangeMind: 'Non, j\'ai changé d\'avis',
    },
    es: {
      loading: 'Estamos buscando los datos del producto, por favor espera',
      notFound: 'No se encontraron datos para este producto',
      confirmTracking: '¿Aún quieres rastrear este artículo?',
      yesTrack: 'Sí, ¡vamos a rastrearlo!',
      noChangeMind: 'No, he cambiado de opinión',
    },
    de: {
      loading: 'Wir suchen die Daten des Produkts, bitte warten',
      notFound: 'Daten für dieses Produkt wurden nicht gefunden',
      confirmTracking: 'Möchten Sie diesen Artikel weiterhin verfolgen?',
      yesTrack: 'Ja, lass es uns verfolgen!',
      noChangeMind: 'Nein, ich habe meine Meinung geändert',
    },
    lt: {
      loading: 'Ieškome produkto duomenų, prašome palaukti',
      notFound: 'Šio produkto duomenų nerasta',
      confirmTracking: 'Ar vis dar norite sekti šį daiktą?',
      yesTrack: 'Taip, sekime jį!',
      noChangeMind: 'Ne, aš pakeičiau savo nuomonę',
    },
  };

  const t = texts[language];


  if (loading) {
    return <p>{t.loading}</p>;
  }

  if (!productData) {
    return <p>{t.notFound}</p>;
  }

  return (
    <div>
      <h3 className='centeredText'>{t.confirmTracking}</h3>
      <div className='hoFlex'>
        <img className='productImg' src={productData.productImg} alt={productData.productTitle} />
        <div className='fillContainer'>
          <p><strong>{productData.productTitle}</strong></p>
          <h3>{productData.productPrice}</h3>
          <div className='hFlexFit clmnG endPos'>
            <button className='btnBasic' onClick={handleConfirm}>{t.yesTrack}</button>
            <button className='btnBasic greyBtn' onClick={handleCancel}>{t.noChangeMind}</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmProduct;