import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import clockGif from './icons/search_4.apng';

const ProductData = ({ productId, userID, trackers, resetForm, setProductData, setShowPlans, refreshUsers, language }) => {
  const [productData, setProductDataState] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      const fetchProductData = async () => {
        try {
          setLoading(true);
          const maxRetries = 6;
          let retries = 0;
          let dataFound = false;

          while (retries < maxRetries && !dataFound) {
            const response = await fetch(`/getProduct?id=${productId}`);
            const data = await response.json();

            if (data.product) {
              setProductDataState(data.product);
              setLoading(false);
              dataFound = true;
            } else {
              retries++;
              await new Promise(resolve => setTimeout(resolve, 10000));
            }
          }

          if (!dataFound) {
            setLoading(false);
          }
        } catch (error) {
          console.error('Error fetching product data:', error);
          setLoading(false);
        }
      };

      fetchProductData();
    }, 10000);
    return () => clearTimeout(timer);
  }, [productId]);

  const handleConfirm = async () => {
    if (!userID) {
      navigate(`/signup/${productId}`);
    } else if (trackers === 0) {
      setShowPlans(true);
    } else {
      try {
        const payload = { productId, userID, priceText: productData.priceText };
        await fetch('/confirmProduct', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(payload)
        });
        setProductDataState(null);
        setProductData(null);
        resetForm();
        refreshUsers();
      } catch (error) {
        console.error('Error confirming product:', error);
      }
    }
  };

  const handleCancel = async () => {
    if (!userID) {
      try {
        const response = await fetch(`/OutDelete?id=${productId}`, {
          method: 'DELETE'
        });
        console.log(response);
        setProductDataState(null);
        setProductData(null);
        resetForm();
      } catch (error) {
        console.error('Error deleting product:', error);
      }
    } else {
      try {
        const response = await fetch(`/delete?id=${productId}&userID=${userID}`, {
          method: 'DELETE'
        });
        console.log(response);
        setProductDataState(null);
        setProductData(null);
        resetForm();
      } catch (error) {
        console.error('Error deleting product:', error);
      }
    }
  };

  const texts = {
    en: {
      looking: 'We are looking for the information about your item...',
      operation: 'This operation might take up to',
      oneMinute: '1 minute',
      notFound: 'Oops! We couldn’t find the item you’re looking for',
      doubleCheck: 'Please double-check the link and try again!',
      back: 'Go Back!',
      rightItem: 'Is this the item you want to track?',
      store: 'Store:',
      trackIt: 'Yes, let\'s track it!',
      wrongItem: 'No, it\'s the wrong item',
    },
    fr: {
      looking: 'Nous recherchons des informations sur votre article...',
      operation: 'Cette opération peut prendre jusqu\'à',
      oneMinute: '1 minute',
      notFound: 'Oups ! Nous n\'avons pas trouvé l\'article que vous recherchez',
      doubleCheck: 'Veuillez vérifier le lien et réessayer !',
      back: 'Retourner!',
      rightItem: 'Est-ce l\'article que vous voulez suivre ?',
      store: 'Magasin :',
      trackIt: 'Oui, suivons-le !',
      wrongItem: 'Non, c\'est le mauvais article',
    },
    es: {
      looking: 'Estamos buscando la información sobre tu artículo...',
      operation: 'Esta operación puede tardar hasta 1 minuto',
      oneMinute: '1 minuto',
      notFound: '¡Vaya! No pudimos encontrar el artículo que buscas',
      doubleCheck: '¡Por favor, verifica el enlace e inténtalo de nuevo!',
      back: '¡Volver!',
      rightItem: '¿Es este el artículo que quieres rastrear?',
      store: 'Tienda:',
      trackIt: '¡Sí, rastreémoslo!',
      wrongItem: 'No, es el artículo equivocado',
    },
    de: {
      looking: 'Wir suchen nach Informationen zu Ihrem Artikel...',
      operation: 'Dieser Vorgang kann bis zu',
      oneMinute: '1 Minute dauern',
      notFound: 'Oops! Wir konnten den gesuchten Artikel nicht finden',
      doubleCheck: 'Bitte überprüfen Sie den Link und versuchen Sie es erneut!',
      back: 'Zurückgehen!',
      rightItem: 'Ist das der Artikel, den Sie verfolgen möchten?',
      store: 'Geschäft:',
      trackIt: 'Ja, lass es uns verfolgen!',
      wrongItem: 'Nein, das ist der falsche Artikel',
    },
    lt: {
      looking: 'Ieškome informacijos apie jūsų prekę...',
      operation: 'Ši operacija gali užtrukti iki',
      oneMinute: '1 minutės',
      notFound: 'Oi! Nepavyko rasti norimos prekės',
      doubleCheck: 'Prašome patikrinti nuorodą ir pabandyti dar kartą!',
      back: 'Grįžti!',
      rightItem: 'Ar tai ta prekė, kurią norite stebėti?',
      store: 'Parduotuvė:',
      trackIt: 'Taip, stebėkime!',
      wrongItem: 'Ne, tai neteisinga prekė',
    }
  };

  const t = texts[language]; // This assigns the correct language's texts based on the selected language  

  return (
    <div>
      {loading ? (
        <>
          <div className='loadingScreen'>
            <img src={clockGif} className='loadingImg' alt="Loading..." />
            <p className='bigP noMarginP centeredText'><strong>{t.looking}</strong></p>
            <p className='noMarginP centeredText'>{t.operation} <span className='redText'>{t.oneMinute}</span></p>
          </div>
        </>
      ) : !productData ? (
        <>
          <div className='loadingScreen'>
            <h2 className='noMarginP'>🫤</h2>
            <p className='centeredText bigP noMarginP'><strong>{t.notFound}</strong></p>
            <p className='centeredText noMarginP'>{t.doubleCheck}</p>
            <button className='btnBasic' onClick={() => window.location.href = "/"}>{t.back}</button>
          </div>
        </>
      ) : (
        <>
          <div className='prodPreview'>
            <h3>{t.rightItem}</h3>
            <div className='hoFlex'>
              <img className='productImg' src={productData.productImg} alt={productData.productTitle} />
              <div className='fillContainer'>
                <p className='bigP noMarginP'><strong>{productData.productTitle}</strong></p>
                <p className='smallP capText'><strong>{t.store} {productData.productCategory}</strong></p>
                <h3 className='redText'>{productData.productPrice}</h3>
                <div className='hFlexFit clmnG endPos'>
                  <button className='btnBasic' onClick={handleConfirm}>{t.trackIt}</button>
                  <button className='btnBasic greyBtn' onClick={handleCancel}>{t.wrongItem}</button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ProductData;