import React from 'react';
import './Terms.css';
import NavBar from './NavBar';
import Footer from './Footer';

const privacySections = [
  {
    title: '1. Information We Collect',
    content: `When you sign up for an account or use our Service, we may collect personal information, including but not limited to:
      - Contact Information: Such as your name, email address, and phone number.
      - Account Information: Including your login credentials and preferences.
      - Payment Information: When you purchase trackers, we collect payment information such as credit card details and billing addresses.`,
  },
  {
    title: '2. Usage Data',
    content: `We collect information about your interactions with the Service, including:
      - Tracking Data: Links to the items you are monitoring and associated price changes.
      - Log Data: IP address, browser type, operating system, referring URLs, pages viewed, and other standard web log data.
      - Cookies and Similar Technologies: We use cookies and similar technologies to collect data about your preferences and interactions with our Service.`,
  },
  {
    title: '3. Communication Data',
    content: `When you contact us or subscribe to notifications, we collect information from those communications, including the content and metadata.`,
  },
  {
    title: '4. How We Use Your Information',
    content: `We use the information we collect to:
      - Monitor prices of the items you track and send you notifications.
      - Customize and improve the Service, including providing personalized recommendations.
      - Process payments and manage your purchases of trackers.`,
  },
  {
    title: '5. Communication',
    content: `We may use your information to:
      - Send you notifications about price changes for tracked items.
      - Respond to your inquiries, support requests, or feedback.
      - Provide updates on changes to our policies, services, or terms.`,
  },
  {
    title: '6. Analytics and Research',
    content: `We may use aggregated, anonymized data for analytics, research, and business development purposes to improve our Service and understand user behavior.`,
  },
  {
    title: '7. Sharing Your Information',
    content: `We may share your information with third-party service providers who assist us with:
      - Payment processing
      - Data analysis and analytics
      - Email and communications management
      - Customer support
      
These service providers are contractually obligated to protect your information and only use it to provide services on our behalf.`,
  },
  {
    title: '8. Legal Compliance and Protection',
    content: `We may disclose your information if required to do so by law or if we believe that such action is necessary to:
      - Comply with legal obligations or government requests.
      - Protect and defend our rights, property, or safety, and that of our users or others.`,
  },
  {
    title: '9. Business Transfers',
    content: `In the event of a merger, acquisition, or sale of all or a portion of our assets, your information may be transferred as part of that transaction.`,
  },
  {
    title: '10. Data Security',
    content: `We take reasonable measures to protect your personal information from unauthorized access, use, alteration, or destruction. However, no security system is completely impenetrable, and we cannot guarantee the security of your data.`,
  },
  {
    title: '11. Your Rights and Choices',
    content: `- Access and Update Your Information: You can access, update, or delete your account information at any time through your account settings. If you need assistance, you can contact our support team.
- Opt-Out of Communications: You can opt-out of receiving promotional emails from us by following the unsubscribe instructions in those emails. However, you may still receive non-promotional communications, such as those related to your account or services.
- Cookie Preferences: You can manage your cookie preferences through your browser settings. Please note that disabling cookies may affect your ability to use certain features of the Service.`,
  },
  {
    title: '12. Children’s Privacy',
    content: `Our Service is not intended for use by children under the age of 13. We do not knowingly collect personal information from children under 13. If we become aware that we have inadvertently collected such information, we will take steps to delete it.`,
  },
  {
    title: '13. International Users',
    content: `If you are accessing our Service from outside [Your Country], please be aware that your information may be transferred to, stored, and processed in [Your Country], where our servers are located. By using our Service, you consent to the transfer of your information to [Your Country].`,
  },
  {
    title: '14. Changes to This Privacy Policy',
    content: `We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. If we make significant changes, we will notify you by email or through the Service. Your continued use of the Service after such changes will constitute your acceptance of the updated policy.`,
  },
  {
    title: '15. Contact Us',
    content: (
      <>
        If you have any questions or concerns about this Privacy Policy or our data practices, please contact our{' '}
        <a className='redLink' href="/support">Support</a>.
      </>
    ),
  },
];

const PrivacyPolicy = ({ language }) => {
  return (
    <div>
      <NavBar language={language} />
      <h1 className='capText redText'>Privacy Policy</h1>
      {privacySections.map((section, index) => (
        <div key={index} className="terms-section">
          <h2 className="terms-title">{section.title}</h2>
          <p className="terms-content">{section.content}</p>
        </div>
      ))}

      <div className="footerHero">
        <h2 className='redText capText'>Ready to track the prices of your favorite items?</h2>
        <button className='btnBasic btnBig btnBlack' onClick={() => window.location.href = "/"}>
          Yeah, Let's do it!
        </button>
      </div>

      <Footer language={language} />
    </div>
  );
};

export default PrivacyPolicy;