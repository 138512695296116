import React, { useState, useEffect } from 'react';
import supabase from './SupabaseClient';

const Footer = ({ language }) => {
  const [session, setSession] = useState(null);

  useEffect(() => {
    const { data: { subscription } } = supabase.auth.onAuthStateChange(
      async (event, session) => {
        setSession(session);
      }
    );

    return () => {
      subscription?.unsubscribe();
    };
  }, []);

  const texts = {
    en: {
      contactSupport: 'Contact Support',
      pricing: 'Pricing',
      availableStores: 'Available Stores',
      termsOfUse: 'Terms of Use',
      privacyPolicy: 'Privacy Policy',
    },
    fr: {
      contactSupport: 'Contacter le Support',
      pricing: 'Tarification',
      availableStores: 'Magasins Disponibles',
      termsOfUse: 'Conditions d\'Utilisation',
      privacyPolicy: 'Politique de Confidentialité',
    },
    es: {
      contactSupport: 'Contactar Soporte',
      pricing: 'Precios',
      availableStores: 'Tiendas Disponibles',
      termsOfUse: 'Términos de Uso',
      privacyPolicy: 'Política de Privacidad',
    },
    de: {
      contactSupport: 'Support Kontaktieren',
      pricing: 'Preise',
      availableStores: 'Verfügbare Geschäfte',
      termsOfUse: 'Nutzungsbedingungen',
      privacyPolicy: 'Datenschutzrichtlinie',
    },
    lt: {
      contactSupport: 'Susisiekti su Pagalba',
      pricing: 'Kainos',
      availableStores: 'Pasiekiamos Parduotuvės',
      termsOfUse: 'Naudojimo Sąlygos',
      privacyPolicy: 'Privatumo Politika',
    },
  };

  const t = texts[language];

  return (
    <footer className='mainFooter redBG'>
      <div>
        <img className='footerLogo' src="Thryllo_Price_Tracker_Footer_Logo.svg" alt="Thryllo Price Tracker Footer Logo" />
        <p className='smallP noMarginP lightText'>© 2024 Thryllo - Price Tracker</p>
      </div>
      <div className="footerLinks">
        {session ? (
          <div>
            <a href="/support" target="_blank">{t.contactSupport}</a><br />
          </div>
        ) : (
          <div>
            <a href="/plans" target="_blank">{t.pricing}</a><br />
          </div>
        )}
        <div>
          <a href="/stores" target="_blank">{t.availableStores}</a><br />
        </div>
        <div>
          <a href="/terms" target="_blank">{t.termsOfUse}</a><br />
        </div>
        <div>
          <a href="/privacy" target="_blank">{t.privacyPolicy}</a><br />
        </div>
      </div>

    </footer>
  );
}

export default Footer;