import React, { useState } from 'react';

const FAQComponent = ({ language }) => {
  const [openFAQIndex, setOpenFAQIndex] = useState(null);

  const texts = {
    en: {
      heading1: 'Got More Questions?',
      heading2: 'We answered the most common',
      faqs: [
        { question: "How does Thryllo track prices?", answer: "Thryllo continuously monitors online stores for price changes on your tracked items and updates you via email whenever there is a change." },
        { question: "How often are prices checked?", answer: "Prices are checked multiple times throughout the day to ensure you get the latest updates as soon as possible." },
        {
          question: "Which online stores does Thryllo support?", answer: (
            <>
              We support a wide range of popular online retailers, continuously adding new stores.
              Check our <a href="/stores">complete list</a> of supported stores.
            </>
          )
        },
        { question: "Is there a limit to the number of items I can track?", answer: "There’s no theoretical limit to the number of items you can track; pricing is based on the number of trackers you use. Feel free to monitor as many items as you like!" },
        { question: "Can I stop tracking an item?", answer: "Yes, you can stop tracking any item at any time through your account dashboard." },
        { question: "Will I be notified of both price increases and decreases?", answer: "Yes, Thryllo notifies you of any price change, whether it’s an increase or a decrease." },
        { question: "Can I track items in different currencies?", answer: "Yes, Thryllo supports multiple currencies and will track the price in the currency of the respective online store." },
        { question: "Is there a way to see the price history of tracked items?", answer: "Yes, Thryllo provides a price history chart for each tracked item so you can see how the price has changed over time." }
      ]
    },
    fr: {
      heading1: 'Vous avez plus de questions ?',
      heading2: 'Nous avons répondu aux plus fréquentes',
      faqs: [
        { question: "Comment Thryllo suit-il les prix ?", answer: "Thryllo surveille en continu les magasins en ligne pour détecter les changements de prix sur vos articles suivis et vous avertit par e-mail à chaque changement." },
        { question: "À quelle fréquence les prix sont-ils vérifiés ?", answer: "Les prix sont vérifiés plusieurs fois par jour pour vous assurer de recevoir les dernières mises à jour dès que possible." },
        {
          question: "Quels magasins en ligne Thryllo prend-il en charge ?", answer: (
            <>
              Thryllo prend en charge une large gamme de commerçants en ligne populaires et ajoute continuellement de nouveaux magasins.
              Consultez <a href="/stores">la liste complète</a> des magasins pris en charge.
            </>
          )
        },
        { question: "Y a-t-il une limite au nombre d'articles que je peux suivre ?", answer: "Il n'y a pas de limite théorique au nombre d'articles que vous pouvez suivre ; le tarif est basé sur le nombre de trackers utilisés. Suivez autant d'articles que vous le souhaitez !" },
        { question: "Puis-je arrêter de suivre un article ?", answer: "Oui, vous pouvez arrêter de suivre un article à tout moment depuis votre tableau de bord." },
        { question: "Serai-je informé des augmentations et des baisses de prix ?", answer: "Oui, Thryllo vous informe de tout changement de prix, qu'il s'agisse d'une augmentation ou d'une baisse." },
        { question: "Puis-je suivre des articles dans différentes devises ?", answer: "Oui, Thryllo prend en charge plusieurs devises et suivra le prix dans la devise du magasin en ligne correspondant." },
        { question: "Puis-je voir l'historique des prix des articles suivis ?", answer: "Oui, Thryllo fournit un graphique d'historique des prix pour chaque article suivi afin que vous puissiez voir comment le prix a évolué au fil du temps." }
      ]
    },
    es: {
      heading1: '¿Tienes más preguntas?',
      heading2: 'Respondimos las más comunes',
      faqs: [
        { question: "¿Cómo rastrea precios Thryllo?", answer: "Thryllo monitorea continuamente las tiendas en línea para detectar cambios en los precios de tus artículos rastreados y te actualiza por correo electrónico cuando hay un cambio." },
        { question: "¿Con qué frecuencia se revisan los precios?", answer: "Los precios se verifican varias veces durante el día para que obtengas las últimas actualizaciones lo antes posible." },
        {
          question: "¿Qué tiendas en línea admite Thryllo?", answer: (
            <>
              Thryllo admite una amplia gama de minoristas en línea populares, agregando continuamente nuevas tiendas.
              Consulta nuestra <a href="/stores">lista completa</a> de tiendas admitidas.
            </>
          )
        },
        { question: "¿Hay un límite en el número de artículos que puedo rastrear?", answer: "No hay un límite teórico en la cantidad de artículos que puedes rastrear; los precios se basan en la cantidad de rastreadores que uses. ¡Sigue tantos artículos como desees!" },
        { question: "¿Puedo dejar de rastrear un artículo?", answer: "Sí, puedes dejar de rastrear cualquier artículo en cualquier momento desde el panel de tu cuenta." },
        { question: "¿Seré notificado tanto de aumentos como de disminuciones de precios?", answer: "Sí, Thryllo te notifica cualquier cambio de precio, ya sea un aumento o una disminución." },
        { question: "¿Puedo rastrear artículos en diferentes monedas?", answer: "Sí, Thryllo admite múltiples monedas y rastrea el precio en la moneda de la tienda en línea respectiva." },
        { question: "¿Hay alguna manera de ver el historial de precios de los artículos rastreados?", answer: "Sí, Thryllo proporciona un gráfico de historial de precios para cada artículo rastreado, para que puedas ver cómo ha cambiado el precio con el tiempo." }
      ]
    },
    de: {
      heading1: 'Haben Sie weitere Fragen?',
      heading2: 'Wir haben die häufigsten beantwortet',
      faqs: [
        { question: "Wie verfolgt Thryllo Preise?", answer: "Thryllo überwacht kontinuierlich Online-Shops auf Preisänderungen bei Ihren verfolgten Artikeln und benachrichtigt Sie per E-Mail, sobald es eine Änderung gibt." },
        { question: "Wie oft werden die Preise überprüft?", answer: "Die Preise werden mehrmals am Tag überprüft, um sicherzustellen, dass Sie die neuesten Updates so schnell wie möglich erhalten." },
        {
          question: "Welche Online-Shops unterstützt Thryllo?", answer: (
            <>
              Thryllo unterstützt eine breite Palette beliebter Online-Händler und fügt kontinuierlich neue Shops hinzu.
              Überprüfen Sie unsere <a href="/stores">vollständige Liste</a> der unterstützten Shops.
            </>
          )
        },
        { question: "Gibt es eine Begrenzung für die Anzahl der Artikel, die ich verfolgen kann?", answer: "Es gibt keine theoretische Begrenzung für die Anzahl der Artikel, die Sie verfolgen können; die Preisgestaltung basiert auf der Anzahl der verwendeten Tracker. Verfolgen Sie so viele Artikel, wie Sie möchten!" },
        { question: "Kann ich das Verfolgen eines Artikels stoppen?", answer: "Ja, Sie können das Verfolgen eines Artikels jederzeit über Ihr Dashboard beenden." },
        { question: "Werde ich sowohl über Preiserhöhungen als auch Preissenkungen benachrichtigt?", answer: "Ja, Thryllo benachrichtigt Sie über jede Preisänderung, sei es eine Erhöhung oder eine Senkung." },
        { question: "Kann ich Artikel in verschiedenen Währungen verfolgen?", answer: "Ja, Thryllo unterstützt mehrere Währungen und wird den Preis in der Währung des jeweiligen Online-Shops verfolgen." },
        { question: "Gibt es eine Möglichkeit, den Preisverlauf der verfolgten Artikel zu sehen?", answer: "Ja, Thryllo bietet ein Preisverlaufsdiagramm für jeden verfolgten Artikel, damit Sie sehen können, wie sich der Preis im Laufe der Zeit verändert hat." }
      ]
    },
    lt: {
      heading1: 'Turite daugiau klausimų?',
      heading2: 'Atsakėme į dažniausiai užduodamus klausimus',
      faqs: [
        { question: "Kaip Thryllo seka kainas?", answer: "Thryllo nuolat stebi internetines parduotuves dėl kainų pokyčių jūsų stebimuose produktuose ir praneša jums el. paštu, kai kaina pasikeičia." },
        { question: "Kaip dažnai tikrinamos kainos?", answer: "Kainos tikrinamos kelis kartus per dieną, kad gautumėte naujausius atnaujinimus kuo greičiau." },
        {
          question: "Kokias internetines parduotuves palaiko Thryllo?", answer: (
            <>
              Thryllo palaiko platų populiarių internetinių mažmenininkų asortimentą ir nuolat prideda naujų parduotuvių.
              Peržiūrėkite mūsų <a href="/stores">palaikomų parduotuvių</a> sąrašą.
            </>
          )
        },
        { question: "Ar yra ribojamas sekamų prekių skaičius?", answer: "Nėra teorinės sekamų prekių ribos; kainodara grindžiama naudojamų stebėtojų skaičiumi. Galite stebėti tiek prekių, kiek norite!" },
        { question: "Ar galiu nustoti sekti prekę?", answer: "Taip, galite bet kuriuo metu nustoti sekti bet kurią prekę per savo paskyros skydelį." },
        { question: "Ar būsiu informuotas apie kainų padidėjimą ir sumažėjimą?", answer: "Taip, Thryllo informuos jus apie bet kokius kainų pokyčius, nesvarbu, ar tai būtų padidėjimas, ar sumažėjimas." },
        { question: "Ar galiu stebėti prekes skirtingomis valiutomis?", answer: "Taip, Thryllo palaiko keletą valiutų ir stebės kainą tos parduotuvės valiuta." },
        { question: "Ar yra būdas pamatyti stebimų prekių kainų istoriją?", answer: "Taip, Thryllo pateikia kainų istorijos diagramą kiekvienai stebimai prekei, kad galėtumėte pamatyti, kaip kaina keitėsi laikui bėgant." }
      ]
    }
  };

  const t = texts[language];

  const toggleFAQ = (index) => {
    setOpenFAQIndex(openFAQIndex === index ? null : index);
  };

  return (
    <div className="faq-container redBG">
      <h2 className='capText noMarginP marginB'>
        {t.heading1}<br/>
        <span className='lightText'>{t.heading2}</span>
      </h2>

      <div className='marginT'>
        {t.faqs.map((faq, index) => (
          <div key={index} className="faq-item">
            <div className="faq-question" onClick={() => toggleFAQ(index)}>
              {faq.question}
              <span className="faq-icon">{openFAQIndex === index ? '-' : '+'}</span>
            </div>
            <div className={`faq-answer ${openFAQIndex === index ? 'show' : 'hide'}`}>
              <p className='noMarginP medLightText'>
                {faq.answer}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQComponent;