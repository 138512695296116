import React from 'react';
import './LandingElements.css';
import FAQComponent from './ProductFAQ';
import LogoCarousel from './StoresCarousel';

const LandingElements = ({ language }) => {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const texts = {
        en: {
            situationTitle: 'Have you ever been in this situation:',
            yesterday: 'Yesterday',
            today: 'Today',
            frustration: 'Frustrating, right?',
            introduction: "We thought so, and that’s why we created Thryllo!",
            trackPrices: 'Track Prices, Buy at The Lowest, Save Money!',
            ThrillDescription: 'Thryllo constantly monitors the prices of your favorite items and sends you an email notification whenever they change. Because why settle for the highest price?',
            stopChecking: 'Stop Checking Links Every Day',
            stopCheckingDescription: "No need to manually check your favorite sites daily. We'll monitor prices for you and notify you the moment they change.",
            goodbyeBookmarks: 'Say Goodbye to Infinite Bookmark Lists',
            goodbyeBookmarksDescription: "Organize your tracking in one place without the need to store dozens of bookmarks. One platform, endless possibilities.",
            saveTime: 'Save Time, Save Money!',
            saveTimeDescription: 'Get notified the moment a price drops, so you can snag the best deals without any effort. Maximize your savings effortlessly.',
            webstores: 'Track Prices from Amazon, Walmart, BestBuy',
            newStores: '& many more...',
            gotcha: 'Gotcha!',
            howItWorks: 'But how does it work?',
            pasteLink: "1. Paste Your Item’s Link",
            pasteLinkDescription: "Copy the URL of the item you want to track from your favorite online store and paste it into the designated field on our site.",
            confirmDetails: "2. Confirm Item's Details",
            confirmDetailsDescription: "Ensure the information provided matches your desired item accurately.",
            getNotified: "3. Get Notified!",
            getNotifiedDescription: "That’s it! We’ll ping your inbox every time there’s a price twist. Happy shopping!",
            readyToSave: 'Ready to save money on your favorite items?',
            buttonText: "Yeah, Let's do it!",
            visitAllStores: "All Available Stores",
            withThrl: "With Thryllo you:",
            imgDecided: "Thryllo-Price-Tracker-Why-You-Need-A-Price-Tracker.svg",
            textDecided: "You finally decided to buy the coffee machine you always wanted for $349!",
            imgPriceChange: "Thryllo-Price-Tracker-Why-You-Need-A-Price-Tracker-Deal-Missed.svg",
            textPriceChange: "the next day you open your laptop, and you notice the price dropped to $219...",
            imgTrackPrices: "Thryllo-Track-Prices-From-Amazon-Walmart-Save-Money.svg",
            imgGain1: "Thryllo-Track-Prices-From-Amazon-Walmart-Gains-1.svg",
            imgGain2: "Thryllo-Track-Prices-From-Amazon-Walmart-Gains-2.svg",
            imgGain3: "Thryllo-Track-Prices-From-Amazon-Walmart-Gains-3.svg",
            imgStep1: "steps/Thryllo-Track-Prices-From-Amazon-Walmart-Paste-Your-Link.svg",
            imgStep2: "steps/Thryllo-Track-Prices-From-Amazon-Walmart-Confirm-Details.svg",
            imgStep3: "steps/Thryllo-Track-Prices-From-Amazon-Walmart-Get-Notified.svg"
        },
        fr: {
            situationTitle: 'As-tu déjà été dans cette situation :',
            yesterday: 'Hier',
            today: 'Aujourd\'hui',
            frustration: 'Frustrant, n’est-ce pas ?',
            introduction: 'C’est ce qu’on pensait, et c’est pourquoi nous avons créé Thryllo!',
            trackPrices: 'Suis les prix, achete au plus bas, économise de l\'argent !',
            ThrillDescription: 'Thryllo surveille constamment les prix de vos articles préférés et vous envoie une notification par e-mail chaque fois qu’ils changent. Parce que pourquoi se contenter du prix le plus élevé ?',
            stopChecking: 'Plus besoin de vérifier vos produits favoris chaque jour',
            stopCheckingDescription: "Pas besoin de vérifier manuellement vos sites préférés tous les jours. Nous surveillerons les prix pour vous et vous notifierons dès qu’ils changent.",
            goodbyeBookmarks: 'Dites adieu à aux interminables listes de favoris',
            goodbyeBookmarksDescription: "Organisez votre suivi au même endroit sans avoir besoin de stocker des dizaines de favoris. Une plateforme, d'innombrables possibilités.",
            saveTime: 'Gagnez du temps, économisez de l’argent !',
            saveTimeDescription: 'Recevez une notification dès qu’un prix baisse, afin que vous puissiez saisir les meilleures offres sans effort. Maximisez vos économies sans effort.',
            webstores: '50+ boutiques en ligne',
            newStores: 'et de nouvelles boutiques ajoutées quotidiennement.',
            gotcha: 'Ok !',
            howItWorks: 'Mais comment ça marche ?',
            pasteLink: '1. Colle le lien de ton article',
            pasteLinkDescription: "Copie l'URL de l'article que tu souhaites suivre depuis ta boutique en ligne préférée et colle-la dans le champ désigné sur notre site.",
            confirmDetails: '2. Confirmez les détails de l\'article',
            confirmDetailsDescription: "Assure-toi que les informations fournies correspondent exactement à l’article souhaité.",
            getNotified: '3. Tu recevras une notification !',
            getNotifiedDescription: "C'est tout ! Nous t'avertirons chaque fois qu'il y a un changement de prix. Bon shopping !",
            readyToSave: 'Prêt à économiser sur vos articles préférés ?',
            buttonText: 'Oui, Carrément !',
            visitAllStores: "Tous les magasins disponibles",
            withThrl: "Avec Thryllo vous:",
            imgDecided: "Thryllo-Price-Tracker-Why-You-Need-A-Price-Tracker-FR.svg",
            textDecided: "Vous avez enfin décidé d'acheter la machine à café que vous vouliez pour 349 € !",
            imgPriceChange: "Thryllo-Price-Tracker-Why-You-Need-A-Price-Tracker-Deal-Missed-FR.svg",
            textPriceChange: "Le lendemain, tu ouvres ton ordi et vois que le prix est tombé à 219 €...",
            imgTrackPrices: "Thryllo-Track-Prices-From-Amazon-Walmart-Save-Money-FR.svg",
            imgGain1: "Thryllo-Track-Prices-From-Amazon-Walmart-Gains-FR-1.svg",
            imgGain2: "Thryllo-Track-Prices-From-Amazon-Walmart-Gains-FR-2.svg",
            imgGain3: "Thryllo-Track-Prices-From-Amazon-Walmart-Gains-FR-3.svg",
            imgStep1: "steps/Thryllo-Track-Prices-From-Amazon-Walmart-Paste-Your-Link-FR.svg",
            imgStep2: "steps/Thryllo-Track-Prices-From-Amazon-Walmart-Confirm-Details-FR.svg",
            imgStep3: "steps/Thryllo-Track-Prices-From-Amazon-Walmart-Get-Notified-FR.svg"
        },
        es: {
            situationTitle: '¿Alguna vez has estado en esta situación:',
            yesterday: 'Ayer',
            today: 'Hoy',
            frustration: '¿Frustrante, verdad?',
            introduction: '¡Así lo pensamos, y por eso creamos a Thryllo!',
            trackPrices: '¡Rastrea precios, compra al más bajo, ahorra dinero!',
            ThrillDescription: 'Thryllo monitorea constantemente los precios de tus artículos favoritos y te envía una notificación por correo electrónico cada vez que cambian. Porque, ¿por qué conformarse con el precio más alto?',
            stopChecking: 'Deja de revisar enlaces cada día',
            stopCheckingDescription: 'No hay necesidad de revisar manualmente tus sitios favoritos todos los días. Monitorearemos los precios por ti y te notificaremos en cuanto cambien.',
            goodbyeBookmarks: 'Di adiós a la sobrecarga de marcadores',
            goodbyeBookmarksDescription: 'Organiza tu seguimiento en un solo lugar sin necesidad de almacenar docenas de marcadores. Una plataforma, posibilidades infinitas.',
            saveTime: '¡Ahorra tiempo, ahorra dinero!',
            saveTimeDescription: 'Recibe una notificación en el momento en que baje un precio, para que puedas conseguir las mejores ofertas sin esfuerzo. Maximiza tus ahorros sin esfuerzo.',
            webstores: '50+ tiendas en línea',
            newStores: 'y nuevas tiendas añadidas diariamente.',
            gotcha: '¡Ok!',
            howItWorks: '¿Pero cómo funciona?',
            pasteLink: '1. Pega el enlace de tu artículo',
            pasteLinkDescription: 'Copia la URL del artículo que deseas rastrear de tu tienda en línea favorita y pégala en el campo designado en nuestro sitio.',
            confirmDetails: '2. Confirma los detalles del artículo',
            confirmDetailsDescription: 'Asegúrate de que la información proporcionada coincida con el artículo que deseas con precisión.',
            getNotified: '3. ¡Recibe notificaciones!',
            getNotifiedDescription: '¡Eso es todo! Te enviaremos un aviso cada vez que haya un cambio de precio. ¡Feliz compra!',
            readyToSave: '¿Listo para ahorrar en tus artículos favoritos?',
            buttonText: '¡Sí, hagámoslo!',
            visitAllStores: "Todas las tiendas disponibles",
            withThrl: "Con Thryllo, tú:",
            imgDecided: "Thryllo-Price-Tracker-Why-You-Need-A-Price-Tracker-ES.svg",
            textDecided: "¡Finalmente decidiste comprar la cafetera que siempre quisiste por 349 $!",
            imgPriceChange: "Thryllo-Price-Tracker-Why-You-Need-A-Price-Tracker-Deal-Missed-ES.svg",
            textPriceChange: "Al día siguiente abres tu portátil y ves que el precio bajó a 219 $...",
            imgTrackPrices: "Thryllo-Track-Prices-From-Amazon-Walmart-Save-Money-ES.svg",
            imgGain1: "Thryllo-Track-Prices-From-Amazon-Walmart-Gains-ES-1.svg",
            imgGain2: "Thryllo-Track-Prices-From-Amazon-Walmart-Gains-ES-2.svg",
            imgGain3: "Thryllo-Track-Prices-From-Amazon-Walmart-Gains-ES-3.svg",
            imgStep1: "steps/Thryllo-Track-Prices-From-Amazon-Walmart-Paste-Your-Link-ES.svg",
            imgStep2: "steps/Thryllo-Track-Prices-From-Amazon-Walmart-Confirm-Details-ES.svg",
            imgStep3: "steps/Thryllo-Track-Prices-From-Amazon-Walmart-Get-Notified-ES.svg"
        },
        de: {
            situationTitle: 'Waren Sie schon einmal in dieser Situation:',
            yesterday: 'Gestern',
            today: 'Heute',
            frustration: 'Frustrierend, oder?',
            introduction: 'Das dachten wir uns, und deshalb haben wir Thryllo geschaffen!',
            trackPrices: 'Preise verfolgen, zum niedrigsten Preis kaufen, Geld sparen!',
            ThrillDescription: 'Thryllo überwacht ständig die Preise Ihrer Lieblingsartikel und sendet Ihnen eine E-Mail-Benachrichtigung, wann immer sich etwas ändert. Warum sich mit dem höchsten Preis zufriedengeben?',
            stopChecking: 'Hören Sie auf, jeden Tag Links zu überprüfen',
            stopCheckingDescription: 'Es ist nicht nötig, täglich manuell Ihre Lieblingsseiten zu überprüfen. Wir überwachen die Preise für Sie und benachrichtigen Sie, sobald sich etwas ändert.',
            goodbyeBookmarks: 'Verabschieden Sie sich von der Bookmark-Überladung',
            goodbyeBookmarksDescription: 'Organisieren Sie Ihr Tracking an einem Ort, ohne Dutzende von Bookmarks speichern zu müssen. Eine Plattform, endlose Möglichkeiten.',
            saveTime: 'Zeit sparen, Geld sparen!',
            saveTimeDescription: 'Lassen Sie sich benachrichtigen, sobald der Preis sinkt, damit Sie die besten Angebote mühelos ergattern können. Maximieren Sie Ihre Ersparnisse ganz einfach.',
            webstores: '50+ Online-Shops',
            newStores: 'und täglich neue Shops hinzugefügt.',
            gotcha: 'Ok!',
            howItWorks: 'Aber wie funktioniert es?',
            pasteLink: '1. Fügen Sie den Link Ihres Artikels ein',
            pasteLinkDescription: 'Kopieren Sie die URL des Artikels, den Sie verfolgen möchten, aus Ihrem Lieblings-Onlineshop und fügen Sie sie in das vorgesehene Feld auf unserer Seite ein.',
            confirmDetails: '2. Bestätigen Sie die Details des Artikels',
            confirmDetailsDescription: 'Stellen Sie sicher, dass die angegebenen Informationen genau mit dem gewünschten Artikel übereinstimmen.',
            getNotified: '3. Benachrichtigen lassen!',
            getNotifiedDescription: 'Das ist alles! Wir senden Ihnen jedes Mal eine Benachrichtigung, wenn es eine Preisänderung gibt. Viel Spaß beim Einkaufen!',
            readyToSave: 'Bereit, bei Ihren Lieblingsartikeln Geld zu sparen?',
            buttonText: 'Ja, lass es uns tun!',
            visitAllStores: "Alle verfügbaren Geschäfte",
            withThrl: "Mit Thryllo du:",
            imgDecided: "Thryllo-Price-Tracker-Why-You-Need-A-Price-Tracker-DE.svg",
            textDecided: "Du hast dich endlich entschieden, die Kaffeemaschine zu kaufen, die du schon immer wolltest, für 349 €!",
            imgPriceChange: "Thryllo-Price-Tracker-Why-You-Need-A-Price-Tracker-Deal-Missed-DE.svg",
            textPriceChange: "Am nächsten Tag siehst du, der Preis ist auf 219 € gefallen...",
            imgTrackPrices: "Thryllo-Track-Prices-From-Amazon-Walmart-Save-Money-DE.svg",
            imgGain1: "Thryllo-Track-Prices-From-Amazon-Walmart-Gains-DE-1.svg",
            imgGain2: "Thryllo-Track-Prices-From-Amazon-Walmart-Gains-DE-2.svg",
            imgGain3: "Thryllo-Track-Prices-From-Amazon-Walmart-Gains-DE-3.svg",
            imgStep1: "steps/Thryllo-Track-Prices-From-Amazon-Walmart-Paste-Your-Link-DE.svg",
            imgStep2: "steps/Thryllo-Track-Prices-From-Amazon-Walmart-Confirm-Details-DE.svg",
            imgStep3: "steps/Thryllo-Track-Prices-From-Amazon-Walmart-Get-Notified-DE.svg"
        },
        lt: {
            situationTitle: 'Ar kada nors buvote tokioje situacijoje:',
            yesterday: 'Vakar',
            today: 'Šiandien',
            frustration: 'Apsunkinantis, ar ne?',
            introduction: 'Mes taip manėme, todėl sukūrėme Thryllo!',
            trackPrices: 'Sekite kainas, pirkite pigiausiai, taupykite pinigus!',
            ThrillDescription: 'Thryllo nuolat stebi jūsų mėgstamų prekių kainas ir siunčia jums el. laiško pranešimą, kai tik jos keičiasi. Kodėl gi nesitenkinti aukščiausia kaina?',
            stopChecking: 'Nustokite kasdien tikrinti nuorodas',
            stopCheckingDescription: 'Nereikia kasdien rankiniu būdu tikrinti savo mėgstamų svetainių. Mes stebėsime kainas už jus ir pranešime, kai jos pasikeis.',
            goodbyeBookmarks: 'Atsisveikinkite su žymių perkrovimu',
            goodbyeBookmarksDescription: 'Organizuokite savo sekimą vienoje vietoje, nesaugodami dešimčių žymių. Viena platforma, begalės galimybių.',
            saveTime: 'Taupykite laiką, taupykite pinigus!',
            saveTimeDescription: 'Būkite informuoti, kai tik kaina sumažėja, kad galėtumėte lengvai gauti geriausius pasiūlymus. Maksimalizuokite savo taupymą be pastangų.',
            webstores: '50+ internetinių parduotuvių',
            newStores: 'ir naujos parduotuvės pridedamos kasdien.',
            gotcha: 'Pagautas!',
            howItWorks: 'Bet kaip tai veikia?',
            pasteLink: '1. Įklijuokite savo prekės nuorodą',
            pasteLinkDescription: 'Nukopijuokite prekės, kurią norite stebėti, URL iš savo mėgstamos internetinės parduotuvės ir įklijuokite ją į numatytą laukelį mūsų svetainėje.',
            confirmDetails: '2. Patvirtinkite prekės informaciją',
            confirmDetailsDescription: 'Įsitikinkite, kad pateikta informacija tiksliai atitinka norimą prekę.',
            getNotified: '3. Gaukite pranešimus!',
            getNotifiedDescription: 'Tai viskas! Mes pranešime jums kiekvieną kartą, kai pasikeis kaina. Laimingų pirkinių!',
            readyToSave: 'Pasiruošę sutaupyti pinigų už savo mėgstamus daiktus?',
            buttonText: 'Taip, pradėkime!',
            visitAllStores: "Visos galimos parduotuvės",
            withThrl: "Su Thryllo jūs:",
            imgDecided: "Thryllo-Price-Tracker-Why-You-Need-A-Price-Tracker-LT.svg",
            textDecided: "Pagaliau nusprendei nusipirkti kavos aparatą, kurio visada norėjai, už 349 €!",
            imgPriceChange: "Thryllo-Price-Tracker-Why-You-Need-A-Price-Tracker-Deal-Missed-LT.svg",
            textPriceChange: "Kitą dieną atsidarai kompiuterį ir pamatai, kad kaina nukrito iki 219 €...",
            imgTrackPrices: "Thryllo-Track-Prices-From-Amazon-Walmart-Save-Money-LT.svg",
            imgGain1: "Thryllo-Track-Prices-From-Amazon-Walmart-Gains-LT-1.svg",
            imgGain2: "Thryllo-Track-Prices-From-Amazon-Walmart-Gains-LT-2.svg",
            imgGain3: "Thryllo-Track-Prices-From-Amazon-Walmart-Gains-LT-3.svg",
            imgStep1: "steps/Thryllo-Track-Prices-From-Amazon-Walmart-Paste-Your-Link-LT.svg",
            imgStep2: "steps/Thryllo-Track-Prices-From-Amazon-Walmart-Confirm-Details-LT.svg",
            imgStep3: "steps/Thryllo-Track-Prices-From-Amazon-Walmart-Get-Notified-LT.svg"
        }
    };

    const t = texts[language];

    return (
        <>
            <div className='slide mSlide pdngM'>
                <h2 className='redText capText centeredText'>{t.situationTitle}</h2>
            </div>

            <div className="parent-container ">
                <div className='slide'>
                    <div className="flex-container">
                        <div className="contentElement">
                            <h3 className='descriptive-text'>{t.textDecided}</h3>
                            <img className="contentElement" src={t.imgDecided} alt={t.textDecided} />
                        </div>
                        <div className="contentElement fEnd">
                            <img className="contentElement" src="Thryllo-Price-Tracker-Why-You-Need-A-Price-Tracker.png" alt={t.textDecided} />
                        </div>
                    </div>
                </div>
                <div className='slide'>
                    <div className="flex-container reverse">
                        <div className="contentElement">
                            <h3 className='descriptive-text'>{t.textPriceChange}</h3>
                            <img className="contentElement" src="Thryllo-Price-Tracker-Why-You-Need-A-Price-Tracker-Deal-Missed.png" alt={t.textPriceChange} />
                        </div>
                        <div className="contentElement fEnd">
                            <img className="contentElement" src={t.imgPriceChange} alt={t.textPriceChange} />
                        </div>
                    </div>
                </div>
            </div>


            {/* <div className='redBG situ'>
                <h3 className='noMarginP capText'>{t.yesterday}</h3>
                <div className='situInner'>
                    <img className='situImg' src="Track_the_prices_of_sneakers.jpeg" alt="Description 1" />
                    <img className='situImg' src="Frame_order.svg" alt="Description 1" />
                    <img className='situImg' src="Happy--Shock-1.png" alt="Description 1" />
                </div>
            </div>

            <div className='redBG situ marginT'>
                <h3 className='noMarginP capText'>{t.today}</h3>
                <div className='situInner'>
                    <img className='situImg' src="Track_the_prices_of_sneakers.jpeg" alt="Description 1" />
                    <img className='situImg' src="Frame_new_price.svg" alt="Description 1" />
                    <img className='situImg' src="Happy--Shock-2.png" alt="Description 1" />
                </div>
            </div> */}
            <div className="parent-container">
                <div className='slide mSlide pdngM'>
                    <h2 className='redText capText centeredText'>
                        {t.frustration}<br/>
                        <span className='lightText'>
                            {t.introduction}
                        </span>
                    </h2>
                </div>
                <div className="flex-container">
                    <div className="contentElement">
                        <h3 className='descriptive-text'>
                            {t.trackPrices}
                        </h3>
                        <p className='descriptive-text'>
                            {t.ThrillDescription}
                        </p>
                        <img className="contentElement" src={t.imgTrackPrices} alt={`${t.trackPrices} ${t.ThrillDescription}`} />
                    </div>
                    <div className="contentElement fEnd">
                        <img className="contentElement" src="Track_Prices_Save_Money_Shop_Smarter.svg" alt={`${t.trackPrices} ${t.ThrillDescription}`} />
                    </div>
                </div>
                <div className='prodPreview'>
                    <h2 className='redText capText centeredText'>{t.withThrl}</h2>
                    <div className='flex-wrapper'>

                        <h3 className='descriptive-text'>{t.stopChecking}</h3>
                        <p className='descriptive-text'>{t.stopCheckingDescription}</p>
                        <img className="contentElement" src={t.imgGain1} alt={`${t.stopChecking} ${t.stopCheckingDescription}`} />
                        
                        <h3 className='descriptive-text'>{t.goodbyeBookmarks}</h3>
                        <p className='descriptive-text'>{t.goodbyeBookmarksDescription}</p>
                        <img className="contentElement" src={t.imgGain2} alt={`${t.goodbyeBookmarks} ${t.goodbyeBookmarksDescription}`} />
                        
                        <h3 className='descriptive-text'>{t.saveTime}</h3>
                        <p className='descriptive-text'>{t.saveTimeDescription}</p>
                        <img className="contentElement" src={t.imgGain3} alt={`${t.saveTime} ${t.saveTimeDescription}`} />
                    
                    </div>
                </div>
            </div>
            <div className='slide mSlide centeredV'>
                <h2 className='redText capText centeredText'>
                    {t.webstores}
                    <span className='lightText'> {t.newStores}</span>
                </h2>
                <LogoCarousel />
                <button className="btnBasic btnBig marginM" onClick={() => window.location.href = '/stores'}>{t.visitAllStores}</button>
            </div>
            <div className='slide mSlide'>
                <h2 className='redText capText centeredText'>
                    {t.gotcha}<br/><span className='lightText'>{t.howItWorks}</span>                    
                </h2>
            </div>
            <div className="parent-container ">
                <div className='slide'>
                    <div className="flex-container">
                        <div className="contentElement">
                            <h3 className='descriptive-text'>{t.pasteLink}</h3>
                            <p className='descriptive-text'>{t.pasteLinkDescription}</p>
                            <img className="contentElement" src={t.imgStep1} alt={`${t.pasteLink} ${t.pasteLinkDescription}`} />
                        </div>
                        <div className="contentElement fEnd">
                            <img className="contentElement" src="steps/Thryllo-Price-Tracker-Paste-A-Link.png" alt={`${t.pasteLink} ${t.pasteLinkDescription}`} />
                        </div>
                    </div>
                </div>
                <div className='slide'>
                    <div className="flex-container reverse">
                        <div className="contentElement">
                            <img className="contentElement" src="steps/Thryllo-Price-Tracker-Confirm-Information.png" alt={`${t.confirmDetails} ${t.confirmDetailsDescription}`} />
                        </div>
                        <div className="contentElement">
                            <h3 className='descriptive-text'>{t.confirmDetails}</h3>
                            <p className='descriptive-text'>{t.confirmDetailsDescription}</p>
                            <img className="contentElement" src={t.imgStep2} alt={`${t.confirmDetails} ${t.confirmDetailsDescription}`} />
                        </div>
                    </div>
                </div>

                <div className="slide">
                    <div className="flex-container">
                        <div className="contentElement">
                            <h3 className='descriptive-text'>{t.getNotified}</h3>
                            <p className='descriptive-text'>{t.getNotifiedDescription}</p>
                            <img className="contentElement" src={t.imgStep3} alt={`${t.getNotified} ${t.getNotifiedDescription}`} />
                        </div>
                        <div className="contentElement fEnd">
                            <img className="contentElement" src="steps/Thryllo-Price-Tracker-Paste-A-Link-Get-Notified.png" alt={`${t.getNotified} ${t.getNotifiedDescription}`} />
                        </div>
                    </div>
                </div>
                <div className='marginT'>
                    <FAQComponent language={language} />
                </div>
                <div className="centeredV marginT pdngM">
                    <h2 className='redText capText noMarginP marginBB centeredText'>{t.readyToSave}</h2>
                    <button className='btnBasic btnBig btnBlack' onClick={scrollToTop}>
                        {t.buttonText}
                    </button>
                </div>
            </div>
        </>
    );
};

export default LandingElements;
